@import "src/index.less";

.ant-space-item {
  width: 100%;
}

.ant-primary-text {
  color: @primary-color;
}

@media(max-width: 992px) {
  .ant-list-item-action {
    display: block;
  }
}
