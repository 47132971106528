@import "utils/filter-polyfill.less";

#siresu::after {
  position: fixed;
  height: 100%;
  width: 100%;
  content: '';
  z-index: -1;
  top: 0;
  left: 0;
  transition: background 0.1s;
  transition-delay: 0.2s;
}
