.logo-container {
  float: left;
  width: 120px;
  height: 40px;
  margin-left: -30px;
  margin-right: 10px;
}

.logo {
  max-height: 100%;
}

.menu-right {
  margin-left: auto !important;
}
