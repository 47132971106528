.mdi-icon {
  margin-bottom: -5px;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  .mdi-icon {
    margin-left: -5px !important;
    margin-bottom: -4px !important;
  }
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  .mdi-icon {
    margin-left: -5px !important;
    margin-bottom: -4px !important;
  }
}
