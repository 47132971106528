@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


body {
  margin: 0;
  font-family: 'Red Hat Display', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.abcRioButton {
  display: inline-block;
}

.container {
  margin-top: 20px;
  margin-bottom: 10px;
}

.max-width {
  width: 100%;
}

.ant-table-row-clickable {
  cursor: pointer;
}

.description-color {
  color: rgba(0, 0, 0, 0.45);
}

@primary-50:  #e8eaf6;
@primary-100: #c5cae9;
@primary-200: #9fa8da;
@primary-300: #7986cb;
@primary-400: #5c6bc0;
@primary-500: #3f51b5;
@primary-600: #3949ab;
@primary-700: #303f9f;
@primary-800: #283593;
@primary-900: #1a237e;

@secondary-900: #8a004e;
@secondary-800: #b00055;
@secondary-700: #c50058;
@secondary-600: #db005c;
@secondary-500: #ec005f;
@secondary-400: #ee0278;
@secondary-300: #f14c91;
@secondary-200: #f484b1;
@secondary-100: #f8b5d0;
@secondary-50: #fce2ec;

@tertiary-900: #de4e02;
@tertiary-800: #e86802;
@tertiary-700: #ee7802;
@tertiary-600: #f48802;
@tertiary-500: #f89302;
@tertiary-400: #f9a326;
@tertiary-300: #fab34c;
@tertiary-200: #fbc97f;
@tertiary-100: #fddeb1;
@tertiary-50: #fef2e0;

@accent-50:  #e0f7fa;
@accent-100: #b2ebf2;
@accent-200: #80deea;
@accent-300: #4dd0e1;
@accent-400: #26c6da;
@accent-500: #00bcd4;
@accent-600: #00acc1;
@accent-700: #0097a7;
@accent-800: #00838f;
@accent-900: #006064;

@primary-color: @primary-500; // primary color for all components
@secondary-color: @tertiary-700;
@accent-color: @accent-300;

@link-color: @accent-color; // link color
@success-color: #02ee02; // success state color
@warning-color: #eeee02; // warning state color
@error-color: #ff2c14; // error state color
@font-size-base: 14px; // major text font size
// @heading-color: rgba(0, 0, 0, 0.85); // heading text color
// @text-color: rgba(0, 0, 0, 0.65); // major text color
// @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
// @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
// @border-color-base: #d9d9d9; // major border color
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
// @surface-color: #F6F6F6;
