.ant-list-item-meta-description {
  word-break: break-word;
}

@media(max-width: 992px) {
  .mobile-margin {
    margin-top: 15px;
  }

  .ant-list-item-action {
    display: none;
  }
}
